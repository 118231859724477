<template>
  <div class="navigation">
    <div class="nav-wrapper">
      <div v-if="code">
        <a id="forwardRest" class="modal-trigger" data-target="modal-reset"></a>
        <ResetComplete @onColseOverlay="closeOverlay" :code="code"></ResetComplete>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import ResetComplete from '@/components/auth/ResetComplete'
export default {
  name: 'Reset',
  data () {
    return {
      code: this.$route.params.code
    }
  },
  components: {
    ResetComplete
  },

  beforeMount () {
    // console.log('Reset: ' + this.code)
    $(function () { document.getElementById('forwardRest').click() })
  },
  methods: {
    resetPassword () {
      //
    },
    closeReset () {
      this.$router.push({ name: 'home' })
    },
    closeOverlay () {
      $('.sidenav').sidenav('close')
      $('.sidenav-overlay').css('display', 'none')
    }
  }
}
</script>

<style scoped>
</style>
